<template>
  <BloomreachExperiencePage />
</template>

<script>
import BloomreachExperiencePage from "~/components/bloomreach/BloomreachExperiencePage.vue";

export default {
  name: "CatchAllRoute",
  components: {
    BloomreachExperiencePage,
  },
};
</script>
